.active {
  color: Dodgerblue;
  cursor: pointer;
}

.inactive {
  color: Tomato;
  cursor: pointer;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}


